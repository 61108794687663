export default class RepairPartInventory {
    id: number = 0;

    repairPartId!: number;

    locationId!: number;

    quantity: number = 0;

    locationName: string = '';

    constructor(init?: Partial<RepairPartInventory>) {
        Object.assign(this, init);
    }
}
