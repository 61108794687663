
import {
    computed, defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import ItemTypeService from '@/services/ItemTypeService';
import ItemType from '@/domain/ItemType';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { TableDefinition } from '@/types';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import coreStore from '@/store/CoreStore';
import InventoryCategory from '@/domain/InventoryCategory';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import RepairType from '@/domain/enums/RepairType';
import Location from '@/domain/Location';
import Repair from '@/domain/Repair/Repair';
import RepairPart from '@/domain/Repair/RepairPart';
import RepairPartQuantity from '@/domain/Repair/RepairPartQuantity';
import LocationService from '@/services/LocationService';
import RepairPartService from '@/services/RepairPartService';

type RepairPartQuantityForm = {
    currentRepairPartQtyBeingEdited: RepairPartQuantity;
    currentRepairPartQtyIndex: number;
    modalTitle: string;
    show: boolean;
};

type RepairForm = {
    currentRepairBeingEdited: Repair;
    currentRepairIndex: number;
    modalTitle: string;
    qtyForm: RepairPartQuantityForm;
    show: boolean;
};

type State = {
    itemEdit: ItemType;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
    availableLocationsForOverridePutAway: Location[];
    availableRepairParts: RepairPart[];
    canMoveInventoryCategories: Array<InventoryCategory>;
    repairForm: RepairForm;
    repairPartDict: Map<number, RepairPart>;
};

export default defineComponent({
    name: 'item-type-list',
    components: {
        BSpinner,
        BAdvancedTable,
        BButton,
        BCol,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BModal,
        BRow,
        DropdownAutocompleteSingleSelect,
        CheckboxInput,
        FormMultiSelect,
        SelectInput,
    },
    setup() {
        const itemTypeService = new ItemTypeService();
        const locationService = new LocationService();
        const repairPartService = new RepairPartService();
        const store = masterDataStore.getInstance().configStore;
        const { confirm } = useDialogBox();
        const { inventoryCategoryStore } = coreStore.getInstance();

        const { validateForm, validationResult, clearResults } = useValidator<ItemType>('item-type');
        const { validateForm: validateRepairForm, validationResult: repairValidationResult } = useValidator<Repair>('repair');
        const { validateForm: validateRepairPartQtyForm, validationResult: repairPartQtyValidationResult } = useValidator<RepairPartQuantity>('repair-part-quantity');

        const repairTypeOptions = [
            { name: 'In Line', value: RepairType.InLine },
            { name: 'In House', value: RepairType.InHouse },
            { name: 'Cannibalization', value: RepairType.Cannibalization },
        ];

        const state = reactive<State>({
            showForm: false,
            itemEdit: new ItemType(),
            modalTitle: '',
            saving: false,
            availableLocationsForOverridePutAway: [],
            availableRepairParts: [],
            canMoveInventoryCategories: inventoryCategoryStore.canMoveInventoryCategories,
            repairForm: {
                currentRepairBeingEdited: new Repair(),
                currentRepairIndex: -1,
                modalTitle: '',
                qtyForm: {
                    currentRepairPartQtyBeingEdited: new RepairPartQuantity(),
                    currentRepairPartQtyIndex: -1,
                    modalTitle: '',
                    show: false,
                },
                show: false,
            },
            repairPartDict: new Map<number, RepairPart>(),
        });

        const currentItemTypeActiveRepairs = computed((): Repair[] => state.itemEdit?.repairs.filter((repair) => !repair.deleted) as Repair[]);

        const itemTypeRepairTable = computed(
            (): TableDefinition<Repair> => ({
                key: 'itemTypeRepairs',
                items: currentItemTypeActiveRepairs.value,
                name: getTitleCaseTranslation('core.domain.availableRepairs'),
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.repairName'),
                    },
                    {
                        key: 'description',
                        label: getTitleCaseTranslation('core.domain.repairDescription'),
                    },
                    {
                        key: 'type',
                        label: getTitleCaseTranslation('core.domain.repairType'),
                    },
                    {
                        key: 'laborBillingRate',
                        label: getTitleCaseTranslation('core.domain.laborBillingRate'),
                    },
                    {
                        key: ['parts'],
                        label: getTitleCaseTranslation('core.domain.repairParts'),
                    },
                    {
                        key: ['partQuantities'],
                        label: getTitleCaseTranslation('core.domain.repairPartQuantities'),
                    },
                ],
            }),
        );

        const overridePutAwayLocationOptions = computed(() => state.availableLocationsForOverridePutAway.map((loc) => ({
            name: loc.name,
            value: loc.id,
        })));

        const repairPartQuantitiesTable = computed(
            (): TableDefinition<RepairPartQuantity> => ({
                key: 'itemTypeRepairPartQuantities',
                items: state.repairForm.currentRepairBeingEdited.repairPartQuantities,
                name: getTitleCaseTranslation('core.domain.repairPartQuantities'),
                columnDefinition: [
                    {
                        key: ['repairPart'],
                        label: getTitleCaseTranslation('core.domain.repairParts'),
                    },
                    {
                        key: 'quantity',
                        label: getTitleCaseTranslation('core.domain.quantity'),
                    },
                ],
            }),
        );

        const table = computed(
            (): TableDefinition<ItemType> => ({
                items: store.itemTypes,
                key: 'itemTypeList',
                name: getTitleCaseTranslation('core.common.itemTypes'),
                columnDefinition: [
                    {
                        key: 'description',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.description'),
                    },
                    {
                        key: 'repairable',
                        label: getTitleCaseTranslation('core.domain.repairable'),
                    },
                ],
            }),
        );

        watch(
            () => state.itemEdit.repairable,
            () => clearResults(),
        );

        onBeforeMount(async () => {
            const getAllLocationsPromise = locationService.getAllLocations();
            const getAllRepairPartsPromise = repairPartService.getAllRepairParts();

            const [allLocationsResp, allRepairParts] = await Promise.all([getAllLocationsPromise, getAllRepairPartsPromise]);

            if (allLocationsResp.success) {
                state.availableLocationsForOverridePutAway = allLocationsResp.locations;
            }

            if (allRepairParts != null) {
                state.availableRepairParts = allRepairParts;
                state.repairPartDict = new Map<number, RepairPart>(state.availableRepairParts.map((part) => [part.id, part]));
            }
        });

        function openAdd() {
            clearResults();
            state.itemEdit = new ItemType();
            state.modalTitle = getTitleCaseTranslation('core.common.newItemType');
            state.showForm = true;
        }

        function openEdit(item: ItemType) {
            clearResults();
            state.itemEdit = new ItemType(item);
            state.itemEdit.repairs
                .flatMap((repair) => repair.repairPartQuantities)
                .forEach((qtyMapping) => {
                    const possibleRepairPart = state.repairPartDict.get(qtyMapping.repairPartId);

                    if (possibleRepairPart) {
                        qtyMapping.repairPart = possibleRepairPart;
                    }
                });
            state.modalTitle = getTitleCaseTranslation('core.common.editItemType');
            state.showForm = true;
        }

        async function openItemTypeHistory(itemType: ItemType) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.ITEM_TYPE, entityId: itemType.id },
            });
        }

        async function deleteRepair(repair: Repair) {
            const confirmationResp = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.deletingARepairCannotBeUndone'),
            });

            if (confirmationResp) {
                repair.deleted = true;
            }
        }

        async function deleteRepairPartQty(repairPartQty: RepairPartQuantity) {
            const confirmationResp = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.deletingARepairPartCannotBeUndone'),
            });

            if (confirmationResp) {
                const repairPartQtyIndex = state.repairForm.currentRepairBeingEdited.repairPartQuantities.indexOf(repairPartQty);

                if (repairPartQtyIndex >= 0) {
                    state.repairForm.currentRepairBeingEdited.repairPartQuantities.splice(repairPartQtyIndex, 1);
                }
            }
        }

        function openRepairAdd() {
            state.repairForm.currentRepairBeingEdited = new Repair();
            state.repairForm.currentRepairIndex = -1;
            state.repairForm.modalTitle = getTitleCaseTranslation('core.common.newRepair');
            state.repairForm.show = true;
        }

        function openRepairEdit(repair: Repair) {
            state.repairForm.currentRepairIndex = state.itemEdit.repairs.indexOf(repair);
            state.repairForm.currentRepairBeingEdited = new Repair(repair);
            state.repairForm.modalTitle = getTitleCaseTranslation('core.common.editRepair');
            state.repairForm.show = true;
        }

        function openRepairPartQtyAdd() {
            state.repairForm.qtyForm.currentRepairPartQtyBeingEdited = new RepairPartQuantity();
            state.repairForm.qtyForm.currentRepairPartQtyIndex = -1;
            state.repairForm.qtyForm.modalTitle = getTitleCaseTranslation('core.common.newRepairPart');
            state.repairForm.qtyForm.show = true;
        }

        function openRepairPartQtyEdit(repairPartQty: RepairPartQuantity) {
            state.repairForm.qtyForm.currentRepairPartQtyIndex = state.repairForm.currentRepairBeingEdited.repairPartQuantities.indexOf(repairPartQty);
            state.repairForm.qtyForm.currentRepairPartQtyBeingEdited = new RepairPartQuantity(repairPartQty);
            state.repairForm.qtyForm.modalTitle = getTitleCaseTranslation('core.common.editRepairPart');
            state.repairForm.qtyForm.show = true;
        }

        function selectRepairPart(repairPart: RepairPart | null) {
            if (repairPart) {
                state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.repairPart = repairPart;
                state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.repairPartId = repairPart.id;
            } else {
                state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.repairPart = new RepairPart();
                state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.repairPartId = 0;
            }
        }

        function upsertRepairPartQtyToRepair() {
            validateRepairPartQtyForm(state.repairForm.qtyForm.currentRepairPartQtyBeingEdited);

            if (repairPartQtyValidationResult.isValid) {
                if (state.repairForm.qtyForm.currentRepairPartQtyIndex >= 0) {
                    state.repairForm.currentRepairBeingEdited.repairPartQuantities.splice(
                        state.repairForm.qtyForm.currentRepairPartQtyIndex,
                        1,
                        state.repairForm.qtyForm.currentRepairPartQtyBeingEdited,
                    );
                } else {
                    state.repairForm.currentRepairBeingEdited.repairPartQuantities.push(state.repairForm.qtyForm.currentRepairPartQtyBeingEdited);
                }

                state.repairForm.qtyForm.show = false;
            }
        }

        function upsertRepairToItemType() {
            validateRepairForm(state.repairForm.currentRepairBeingEdited);

            if (repairValidationResult.isValid) {
                if (state.repairForm.currentRepairIndex >= 0) {
                    state.itemEdit.repairs.splice(state.repairForm.currentRepairIndex, 1, state.repairForm.currentRepairBeingEdited);
                } else {
                    state.itemEdit.repairs.push(state.repairForm.currentRepairBeingEdited);
                }

                state.repairForm.show = false;
            }
        }

        async function save() {
            validateForm(state.itemEdit);

            if (validationResult.isValid) {
                state.saving = true;

                if (state.itemEdit.id > 0) {
                    const response = await itemTypeService.updateItemType(state.itemEdit);
                    if (response) {
                        state.showForm = false;
                    }
                } else {
                    const response = await itemTypeService.addNewItemType(state.itemEdit);
                    if (response) {
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        return {
            table,
            deleteRepair,
            deleteRepairPartQty,
            getTitleCaseTranslation,
            itemTypeRepairTable,
            openAdd,
            openEdit,
            openItemTypeHistory,
            openRepairAdd,
            openRepairEdit,
            openRepairPartQtyAdd,
            openRepairPartQtyEdit,
            overridePutAwayLocationOptions,
            repairPartQtyValidationResult,
            repairPartQuantitiesTable,
            repairTypeOptions,
            repairValidationResult,
            save,
            selectRepairPart,
            state,
            upsertRepairPartQtyToRepair,
            upsertRepairToItemType,
            validationResult,
        };
    },
});
