import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "error" }
const _hoisted_2 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showForm,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.showForm) = $event)),
        centered: "",
        size: "lg",
        title: _ctx.state.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            modelValue: _ctx.state.itemEdit.description,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.itemEdit.description) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.description'),
            error: _ctx.validationResult.model.description,
            autofocus: ""
          }, null, 8, ["modelValue", "label", "error"]),
          _createVNode(_component_form_multi_select, {
            modelValue: _ctx.state.itemEdit.sortInventoryCategories,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.itemEdit.sortInventoryCategories) = $event)),
            "search-by": "description",
            data: _ctx.state.canMoveInventoryCategories,
            label: _ctx.getTitleCaseTranslation('core.domain.sortInventoryCategories')
          }, null, 8, ["modelValue", "data", "label"]),
          _createVNode(_component_select_input, {
            model: _ctx.state.itemEdit.overridePutAwayLocationId,
            "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.itemEdit.overridePutAwayLocationId) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.overridePutAwayLocation'),
            options: _ctx.overridePutAwayLocationOptions,
            text: "name",
            value: "value"
          }, null, 8, ["model", "label", "options"]),
          _createVNode(_component_checkbox_input, {
            modelValue: _ctx.state.itemEdit.repairable,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.itemEdit.repairable) = $event)),
            "inline-right": "",
            label: _ctx.getTitleCaseTranslation('core.domain.repairable')
          }, null, 8, ["modelValue", "label"]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.validationResult.model.repairs), 1)
          ]),
          (_ctx.state.itemEdit.repairable)
            ? (_openBlock(), _createBlock(_component_b_advanced_table, {
                key: 0,
                "allow-overflow": "",
                "table-array": [_ctx.itemTypeRepairTable]
              }, {
                toprow: _withCtx(() => [
                  _createVNode(_component_b_button, {
                    size: "sm",
                    variant: "tertiary",
                    onClick: _ctx.openRepairAdd
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addRepair')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                "cell(type)": _withCtx((row) => [
                  _createTextVNode(_toDisplayString(_ctx.repairTypeOptions.find((opt) => opt.value === row.item.type)?.name ?? ''), 1)
                ]),
                "cell(parts)": _withCtx((row) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.repairPartQuantities, (part, index) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: `part-${index}`
                    }, _toDisplayString(part.repairPart.description), 1))
                  }), 128))
                ]),
                "cell(partQuantities)": _withCtx((row) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.repairPartQuantities, (part, index) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: `part-quantity-${index}`
                    }, _toDisplayString(part.quantity), 1))
                  }), 128))
                ]),
                action: _withCtx((row) => [
                  _createVNode(_component_b_dropdown, {
                    "close-on-click": "",
                    "drop-up": "",
                    "no-caret": ""
                  }, {
                    "button-content": _withCtx(() => [
                      _createVNode(_component_faicon, { icon: "ellipsis-h" })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_dropdown_item, {
                        size: "sm",
                        onClick: () => _ctx.openRepairEdit(row.item)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]),
                      _createVNode(_component_b_dropdown_item, {
                        size: "sm",
                        onClick: () => _ctx.deleteRepair(row.item)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["table-array"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.repairForm.show,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.repairForm.show) = $event)),
        centered: "",
        title: _ctx.state.repairForm.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            onClick: _ctx.upsertRepairToItemType
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.repairForm.currentRepairBeingEdited.name,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.repairForm.currentRepairBeingEdited.name) = $event)),
                    error: _ctx.repairValidationResult.model.name,
                    label: _ctx.getTitleCaseTranslation('core.domain.repairName')
                  }, null, 8, ["modelValue", "error", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.repairForm.currentRepairBeingEdited.description,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.repairForm.currentRepairBeingEdited.description) = $event)),
                    error: _ctx.repairValidationResult.model.description,
                    label: _ctx.getTitleCaseTranslation('core.domain.repairDescription')
                  }, null, 8, ["modelValue", "error", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_select_input, {
                    model: _ctx.state.repairForm.currentRepairBeingEdited.type,
                    "onUpdate:model": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.repairForm.currentRepairBeingEdited.type) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.repairType'),
                    options: _ctx.repairTypeOptions,
                    text: "name",
                    value: "value"
                  }, null, 8, ["model", "label", "options"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.repairForm.currentRepairBeingEdited.laborBillingRate,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.repairForm.currentRepairBeingEdited.laborBillingRate) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.laborBillingRate'),
                    "number-options": { allowsDecimal: true },
                    type: "number"
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.repairValidationResult.model.repairPartQuantities), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_advanced_table, {
            "allow-overflow": "",
            "table-array": [_ctx.repairPartQuantitiesTable]
          }, {
            toprow: _withCtx(() => [
              _createVNode(_component_b_button, {
                size: "sm",
                variant: "tertiary",
                onClick: _ctx.openRepairPartQtyAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addRepairPart')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            "cell(repairPart)": _withCtx((row) => [
              _createTextVNode(_toDisplayString(row.item.repairPart.description), 1)
            ]),
            action: _withCtx((row) => [
              _createVNode(_component_b_dropdown, {
                "close-on-click": "",
                "drop-up": "",
                "no-caret": ""
              }, {
                "button-content": _withCtx(() => [
                  _createVNode(_component_faicon, { icon: "ellipsis-h" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_dropdown_item, {
                    size: "sm",
                    onClick: () => _ctx.openRepairPartQtyEdit(row.item)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_b_dropdown_item, {
                    size: "sm",
                    onClick: () => _ctx.deleteRepairPartQty(row.item)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["table-array"])
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.repairForm.qtyForm.show,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.repairForm.qtyForm.show) = $event)),
        centered: "",
        title: _ctx.state.repairForm.qtyForm.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            onClick: _ctx.upsertRepairPartQtyToRepair
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_dropdown_autocomplete_single_select, {
                    "model-value": _ctx.state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.repairPart.description,
                    data: _ctx.state.availableRepairParts,
                    error: _ctx.repairPartQtyValidationResult.model.repairPartId,
                    label: _ctx.getTitleCaseTranslation('core.domain.repairPart'),
                    "search-by": "description",
                    onOnSelect: _ctx.selectRepairPart
                  }, null, 8, ["model-value", "data", "error", "label", "onOnSelect"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.quantity,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.repairForm.qtyForm.currentRepairPartQtyBeingEdited.quantity) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.quantity'),
                    type: "number"
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItemType')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        "cell(repairable)": _withCtx((row) => [
          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation(row.item.repairable ? 'core.common.yes' : 'core.common.no')), 1)
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openItemTypeHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array"])
    ]),
    _: 1
  }))
}