
import { defineComponent } from 'vue';
import ItemTypeList from '@/modules/master-data/views/config/ItemTypeList.vue';

export default defineComponent({
    name: 'item-type-config',
    components: { ItemTypeList },

    setup() {
        return {

        };
    },

});
