import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import RepairPart from '@/domain/Repair/RepairPart';
import RepairPartInventory from '@/domain/Repair/RepairPartInventory';

export default class RepairPartApiService {
    public async getAllRepairParts(): Promise<DataAccessResponse<Array<RepairPart>>> {
        const response: AxiosResponse<DataAccessResponse<Array<RepairPart>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair-part`,
        });
        return new DataListAccessResponseClass<RepairPart>(response, RepairPart).response;
    }

    public async getAllRepairPartsWithLocation(locationId: number): Promise<DataAccessResponse<Array<RepairPart>>> {
        const response: AxiosResponse<DataAccessResponse<Array<RepairPart>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair-part/with-location/${locationId}`,
        });
        return new DataListAccessResponseClass<RepairPart>(response, RepairPart).response;
    }

    public async getRepairPartById(id: number): Promise<DataAccessResponse<RepairPart>> {
        const response: AxiosResponse<DataAccessResponse<RepairPart>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair-part/${id}`,
        });

        return new DataAccessResponseClass<RepairPart>(response, RepairPart).response;
    }

    public async addRepairPart(dto: RepairPart): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/repair-part`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateRepairPart(dto: RepairPart): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/repair-part`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async deleteRepairPart(id: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/repair-part/${id}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async getRepairPartInventoryByRepairPartId(id: number): Promise<DataAccessResponse<Array<RepairPartInventory>>> {
        const response: AxiosResponse<DataAccessResponse<Array<RepairPartInventory>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair-part/all-inventory/${id}`,
        });
        return new DataListAccessResponseClass<RepairPartInventory>(response, RepairPartInventory).response;
    }

    public async getRepairPartInventoryById(id: number): Promise<DataAccessResponse<RepairPartInventory>> {
        const response: AxiosResponse<DataAccessResponse<RepairPartInventory>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/repair-part/inventory/${id}`,
        });

        return new DataAccessResponseClass<RepairPartInventory>(response, RepairPartInventory).response;
    }

    public async addRepairPartInventory(dto: RepairPartInventory): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/repair-part/inventory`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateRepairPartInventory(dto: RepairPartInventory): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/repair-part/inventory`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
